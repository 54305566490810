import React, { useState } from 'react'
import './AuthenticationStyle.css';
// import ConfirmPassword from './ConfirmPassword';
import ForgotPassword from './ForgotPassword';
import LoginPage from './LoginPage';
// import SignupPage from './SignupPage';

const Authentication = () => {

  const [pageToggler, setPageToggler] = useState(0);

  return (
    <div className="autheticationWrapper">
      <div className="autheticationBox">
        <div className="loginLeft">
          {pageToggler === 0 && <LoginPage setPageToggler={setPageToggler} />}
          {/* {pageToggler === 1 && <SignupPage setPageToggler={setPageToggler} />} */}
          {pageToggler === 2 && <ForgotPassword setPageToggler={setPageToggler} />}
        </div>
        <div className="loginRight">
          <img src="/assets/img/LoginBG.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Authentication