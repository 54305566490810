import React from 'react'
import Button from '../Button/Button';
import './SlotSelectorStyle.css';

const SlotSelector = ({id,name,value,text,checked,disabled, onChange, onClick}) => {
  return (
    <div className="slotWrapper">
        <input type="checkbox" checked={checked} disabled={disabled} id={id} name={name} value={value} onChange={onChange} />
        <label htmlFor={id} disabled={disabled}>
            {text}
        </label>  
        <div className='slotBtn'>
          <Button className={'blueButton mt-10'} name={'Confirm'} onClick={onClick} />
        </div>
    </div>
  )
}

export default SlotSelector