import React from "react";
import './customUploadBoxStyle.css';

const CustomUploadBox = ({ label,onChange ,value,handleRemove,accept ,isImportant, uid,active, handleDelete}) => {

    return (
        <div className="uploadBoxOuter">
            <p className="label">{label} {isImportant? <span className='impMark'>*</span>:null }</p>
            <div className="upload-box-inner">
                <label htmlFor={uid}>
                    <span className='upload-btn'>Choose File</span>
                   {!value? "No File Chosen":value }
                </label>
               {!value? <input type="file" id={uid} value={e => e.target.value} onChange={onChange} accept={accept} />: "" }

                <div class={`upload-box-icons ${active?"active":""}`} onClick={ handleDelete} >
                    <img src="/assets/icons/trash.png" alt="" onClick={handleRemove}/>

                </div>
            </div>
        </div>
    )
}

export default CustomUploadBox