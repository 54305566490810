import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../../componets/Button/Button'
import InputField from '../../componets/InputField/InputField'
import PageLoader from '../../componets/PageLoader/PageLoader'
import ErrorToast from '../../componets/Toast/ErrorToast'
import { clearState, loginUsers, userSelector, validateMentee } from '../../reducers/userSlice'
const LoginPage = ({ setPageToggler }) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Validation states
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);

  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [params] = useSearchParams();
  const token = params.get("token");

  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailValidation(false)
  }

  const handlePassChange = (e) => {
    let pass = e.target.value;
    setPassword(e.target.value)
    if (pass === "") {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setIsShowError(true);
      setErrMessage("Please fill both fields");
    } else {
      const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(email)) {
        setEmailValidation(true);
      }
      else {
        setEmailValidation(false);
        const obj = {
          email: email,
          password: password
        }
        dispatch(loginUsers(obj))
      }
    }
  };

  const handleKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!email || !password) {
        setIsShowError(true);
        setErrMessage("Please fill both fields");
      } else {
        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
          setEmailValidation(true);
        }
        else {
          setEmailValidation(false);
          const obj = {
            email: email,
            password: password
          }
          dispatch(loginUsers(obj))
        }
      }
    }
  };

  useEffect(() => {
    if(token) dispatch(validateMentee(token));
  },[token])

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      navigate("/", { replace: true });
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isError, isSuccess]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="componentLogin">

        <div className="formWrapper">
          <div className="BlueTag mb-10">Login Account</div>
          <h2 className="heading-2 mb-20">Welcome to GetPrepped!</h2>
          <InputField onKeyPress={handleKeyPressSubmit} value={email} onChange={handleEmailChange} type={'email'} className={'FormInput mb-20'} placeholder={'Enter your registered Email'} id={'LoginName'} isError={emailValidation} erroMessage={'Please enter valid email address'} labelName={'Registered Email'} />
          <InputField onKeyPress={handleKeyPressSubmit} value={password} onChange={handlePassChange} type={'password'} className={'FormInput mb-15'} placeholder={'Enter your Password'} id={'LoginPassword'} isError={passwordValidation} erroMessage={'Please enter password'} labelName={'Password'} />
        </div>

        <div className="formOptions">
          {/* <InputField checkBoxWrap={'checkBoxWrap'} type={'checkBox'} className={'customCheckBox'} placeholder={''} id={'Remember'} labelName={'Remember Me'}/> */}
          <div className="forgetPassword" onClick={() => setPageToggler(2)}>Forgot Password?</div>
        </div>

        <div className="buttonWrapper mt-30">
          <Button className={'blackButton'} name={'Log in'} onClick={handleSubmit} />
          {/* <Button className={'whiteButton imgButton mt-10'} name={'Sign in with Google'} src={'/assets/icons/googleLogo.svg'}/> */}
        </div>

        <div className="policyWrapper mt-20">
          <p className="policyText">Copyright @getprepped</p>
          <p className="policyText">|</p>
          <p className="policyText">Privacy Policy</p>
        </div>

        <div className='lastWrapper mt-20'><a className="policyText" href='https://mentor.getprepped.in/' rel='nofollow' >Login as a Mentor</a></div>

      </div>
    </>
  )
}

export default LoginPage