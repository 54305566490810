import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/enviroment";

let token;
let menteeId;

let setTokenValues = () => {
  token = localStorage.getItem("token");
  menteeId = localStorage.getItem("id");
};

export const saveMenteeFeedbackAnswers = createAsyncThunk(
  "feedback/saveMenteeFeedbackAnswers",
  async ({ feedbackQues, bookingId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/getPrepped/mentee/" +
        menteeId +
        "/booking/" +
        bookingId +
        "/saveMenteeFeedbackAnswers",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(feedbackQues)
        }
      );

      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      } let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {

        return data.Data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {

      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getFeedbackAnswers = createAsyncThunk(
  "call/getFeedbackAnswers",
  async (bookingId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/getPrepped/mentee/booking/" +
        bookingId +
        "/getFeedbackAnswers",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          }
        });
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    feedbackData: [],
    feedbackAnswer: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    }
  },
  extraReducers: {
    [saveMenteeFeedbackAnswers.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [saveMenteeFeedbackAnswers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [saveMenteeFeedbackAnswers.pending]: (state) => {
      state.isFetching = true;
    },
    [getFeedbackAnswers.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.feedbackAnswer = payload;
      return state;
    },
    [getFeedbackAnswers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getFeedbackAnswers.pending]: (state) => {
      state.isFetching = true;
    },
  }
})

export const { clearState } = feedbackSlice.actions;
export const feedbackSliceFunction = (state) => state.feedback;