import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/enviroment";

let token;
let menteeId;

let setTokenValues = () => {
  token = localStorage.getItem("token");
  menteeId = localStorage.getItem("id");
};

export const getMenteeDetails = createAsyncThunk(
  "profile/getMenteeDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/getPrepped/mentee/" +
        menteeId +
        "/getMenteeDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          }
        });
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();
      console.log(data)

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveMenteeDetails = createAsyncThunk(
  "profile/saveMenteeDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/getPrepped/mentee/" +
        menteeId +
        "/saveMenteeDetails",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Content-length": "1024211",
            Authorization: token,
          },
          body: JSON.stringify(obj)
        }
      );

      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      } let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {

        return data.Data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {

      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "login/changePassword",
  async ({newPassword, oldPassword}, thunkAPI) => {
    try {
      setTokenValues();
      const obj = {
        oldPassword: oldPassword,
        password: newPassword
      }
      const response = await fetch(`${environment.baseURL}/api/getPrepped/mentee/changePassword`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    menteeData: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    }
  },
  extraReducers: {
    [getMenteeDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.menteeData = payload;
      return state;
    },
    [getMenteeDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMenteeDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [saveMenteeDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [saveMenteeDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [saveMenteeDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [changePassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [changePassword.pending]: (state) => {
      state.isFetching = true;
    },
  }
})

export const { clearState } = profileSlice.actions;

export const profileSliceFunction = (state) => state.profile;