import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../componets/Button/Button'
import InputField from '../../componets/InputField/InputField'
import PageLoader from '../../componets/PageLoader/PageLoader';
import ErrorToast from '../../componets/Toast/ErrorToast';
import SuccessToast from '../../componets/Toast/SuccessToast';
import { clearState, forgotPassword, userSelector } from '../../reducers/userSlice';

const ForgotPassword = ({ setPageToggler }) => {

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [email, setEmail] = useState();
  const dispatch = useDispatch();

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  const handleSubmit = () => {
    const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(email)) dispatch(forgotPassword(email))
    else {
      setIsShowError(true);
      setErrMessage("Please enter valid email address");
    }
  };

  const handlePressSubmit = (e) => {
    if (e.key === "Enter") {
      const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (filter.test(email)) dispatch(forgotPassword(email))
      else {
        setIsShowError(true);
        setErrMessage("Please enter valid email address");
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsShowSuccess(true);
      setSuccessMessage("Success! Check your email for reset link");
      setTimeout(() => {
        setPageToggler(0);
      }, 3000)
      dispatch(clearState());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isError])

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className="componentForget">

        <div className="formWrapper forgetWrapper">
          <div className="BlueTag mb-10">Registered Account</div>
          <h2 className="heading-2 mb-20">Forgot Password</h2>
          <InputField onKeyPress={handlePressSubmit} value={email} onChange={e => setEmail(e.target.value)} type={'email'} className={'FormInput'} placeholder={'Enter your registered Email'} id={'ForgetPass'} labelName={'Registered Email'} />
        </div>

        <div className="buttonWrapper">
          <Button className={'blackButton'} name={'Send Link'} onClick={handleSubmit} />
          <Button className={'whiteButton mt-10'} name={'Back to Login'} onClick={() => { setPageToggler(0) }} />
        </div>

        <div className="policyWrapper mt-20">
          <p className="policyText">Copyright @getprepped</p>
          |
          <p className="policyText">Privacy Policy</p>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword