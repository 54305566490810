import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Authentication from './pages/authentication/Authentication';
import Header from './componets/header/Header';
import SideMenu from './componets/sidemenu/SideMenu';
import ConfirmPassword from "./pages/authentication/ConfirmPassword";
import MenteeProfile from './pages/menteeProfile/MenteeProfile';
import EditProfile from './pages/editProfile/EditProfile';
import CallsBooking from './pages/callsBooking/CallsBooking';
import Feedback from './pages/feedback/Feedback';
import ViewFeedback from './pages/feedback/ViewFeedback';
import CreateFeedback from './pages/createFeedback/CreateFeedback';
import { useSelector } from 'react-redux';
import { userSelector } from './reducers/userSlice'
import { PrivateRoute } from './helpers/PrivateRoute'
import { useState } from 'react';
function App() {

  const { token } = useSelector(userSelector);
  const location = useLocation();
  const pathname = location.pathname;
  const [isMenu, setIsMenu] = useState(false);

  return (
    <>
      <Header setIsMenu={setIsMenu} isMenu={isMenu} />
      <div className='MainDivAppUpcoming'>
        <div className="appContainer">
          {((pathname !== "/authentication" && pathname !== "/createFeedback") && (token || pathname === "/")) && <SideMenu isMenu={isMenu} setIsMenu={setIsMenu} />}
          <div className={`routesContainer ${(pathname !== "/createFeedback" || token) ? "LoginWrapper" : ""}`}>
            <Routes>
              <Route path="/authentication" exact element={<Authentication />} />
              <Route path='/changePassword/:token' element={<ConfirmPassword />} />

              <Route path="/" exact element={<PrivateRoute />} >
                <Route path="/" exact element={<CallsBooking />} />
                <Route path="/profile" exact element={<MenteeProfile />} />
                <Route path="/editprofile" exact element={<EditProfile />} />
                <Route path="/feedback" exact element={<Feedback />} />
                <Route path="/viewFeedback/:id" exact element={<ViewFeedback />} />
                <Route path="/createFeedback" exact element={<CreateFeedback />} />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
