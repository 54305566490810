import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../componets/Button/Button'
import PageLoader from '../../componets/PageLoader/PageLoader';
import { feedbackSliceFunction, getFeedbackAnswers } from '../../reducers/feedbackSlice';
import './FeedbackStyle.css';

const ViewFeedback = () => {
    const [toggle, setToggle] = useState(false)
    const [mentorFeedback, setMentorFeedback] = useState([]);
    const [menteeFeedback, setMenteeFeedback] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();

    const pathname = window.location.pathname;

    const { feedbackAnswer, isFetching } = useSelector(feedbackSliceFunction);

    useEffect(() => {
        dispatch(getFeedbackAnswers(id));
    }, [])

    useEffect(() => {
        setMentorFeedback(feedbackAnswer?.getPrepFeedbackAnswers?.filter((item) => item.mentorId !== null));
        setMenteeFeedback(feedbackAnswer?.getPrepFeedbackAnswers?.filter((item) => item.menteeId !== null));
    }, [feedbackAnswer]);
    
    const options = [1, 2, 3, 4, 5];

    return (
        <>
            {isFetching && <PageLoader />}
            <div className={`viewFeedbackWrappper ${pathname === "/viewFeedback" ? "conditionFeedWrapper" : ""}`}>
                <div className="profileHeader viewHeader">
                    <h2 className="heading-3">Feedback Received / <span>{feedbackAnswer?.GetPreppedAdmin?.firstName ? `${feedbackAnswer?.GetPreppedAdmin?.firstName} ${feedbackAnswer?.GetPreppedAdmin?.lastName}` : "---"}</span></h2>

                    <div className="feedbackDetails">
                        <div className="feedbackBox">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.9583 2.9665V1.6665C13.9583 1.32484 13.675 1.0415 13.3333 1.0415C12.9917 1.0415 12.7083 1.32484 12.7083 1.6665V2.9165H7.29165V1.6665C7.29165 1.32484 7.00832 1.0415 6.66665 1.0415C6.32499 1.0415 6.04165 1.32484 6.04165 1.6665V2.9665C3.79165 3.17484 2.69999 4.5165 2.53332 6.50817C2.51665 6.74984 2.71665 6.94984 2.94999 6.94984H17.05C17.2917 6.94984 17.4917 6.7415 17.4667 6.50817C17.3 4.5165 16.2083 3.17484 13.9583 2.9665Z" fill="#3397EF" />
                                <path d="M15.8333 12.5C13.9917 12.5 12.5 13.9917 12.5 15.8333C12.5 16.4583 12.675 17.05 12.9833 17.55C13.5583 18.5167 14.6167 19.1667 15.8333 19.1667C17.05 19.1667 18.1083 18.5167 18.6833 17.55C18.9917 17.05 19.1667 16.4583 19.1667 15.8333C19.1667 13.9917 17.675 12.5 15.8333 12.5ZM17.5583 15.475L15.7833 17.1167C15.6667 17.225 15.5083 17.2833 15.3583 17.2833C15.2 17.2833 15.0417 17.225 14.9167 17.1L14.0917 16.275C13.85 16.0333 13.85 15.6333 14.0917 15.3917C14.3333 15.15 14.7333 15.15 14.975 15.3917L15.375 15.7917L16.7083 14.5583C16.9583 14.325 17.3583 14.3417 17.5917 14.5917C17.825 14.8417 17.8083 15.2333 17.5583 15.475Z" fill="#3397EF" />
                                <path d="M16.6667 8.2002H3.33333C2.875 8.2002 2.5 8.5752 2.5 9.03353V14.1669C2.5 16.6669 3.75 18.3335 6.66667 18.3335H10.775C11.35 18.3335 11.75 17.7752 11.5667 17.2335C11.4 16.7502 11.2583 16.2169 11.2583 15.8335C11.2583 13.3085 13.3167 11.2502 15.8417 11.2502C16.0833 11.2502 16.325 11.2669 16.5583 11.3085C17.0583 11.3835 17.5083 10.9919 17.5083 10.4919V9.04186C17.5 8.5752 17.125 8.2002 16.6667 8.2002ZM7.675 15.1752C7.51667 15.3252 7.3 15.4169 7.08333 15.4169C6.86667 15.4169 6.65 15.3252 6.49167 15.1752C6.34167 15.0169 6.25 14.8002 6.25 14.5835C6.25 14.3669 6.34167 14.1502 6.49167 13.9919C6.575 13.9169 6.65833 13.8585 6.76667 13.8169C7.075 13.6835 7.44167 13.7585 7.675 13.9919C7.825 14.1502 7.91667 14.3669 7.91667 14.5835C7.91667 14.8002 7.825 15.0169 7.675 15.1752ZM7.675 12.2585C7.63333 12.2919 7.59167 12.3252 7.55 12.3585C7.5 12.3919 7.45 12.4169 7.4 12.4335C7.35 12.4585 7.3 12.4752 7.25 12.4835C7.19167 12.4919 7.13333 12.5002 7.08333 12.5002C6.86667 12.5002 6.65 12.4085 6.49167 12.2585C6.34167 12.1002 6.25 11.8835 6.25 11.6669C6.25 11.4502 6.34167 11.2335 6.49167 11.0752C6.68333 10.8835 6.975 10.7919 7.25 10.8502C7.3 10.8585 7.35 10.8752 7.4 10.9002C7.45 10.9169 7.5 10.9419 7.55 10.9752C7.59167 11.0085 7.63333 11.0419 7.675 11.0752C7.825 11.2335 7.91667 11.4502 7.91667 11.6669C7.91667 11.8835 7.825 12.1002 7.675 12.2585ZM10.5917 12.2585C10.4333 12.4085 10.2167 12.5002 10 12.5002C9.78333 12.5002 9.56667 12.4085 9.40833 12.2585C9.25833 12.1002 9.16667 11.8835 9.16667 11.6669C9.16667 11.4502 9.25833 11.2335 9.40833 11.0752C9.725 10.7669 10.2833 10.7669 10.5917 11.0752C10.7417 11.2335 10.8333 11.4502 10.8333 11.6669C10.8333 11.8835 10.7417 12.1002 10.5917 12.2585Z" fill="#3397EF" />
                            </svg>
                            {feedbackAnswer ? moment.utc(feedbackAnswer?.startTime).format("ddd DD MMM YYYY") : "---"}
                        </div>

                        <div className="feedbackBox">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99999 1.6665C5.40832 1.6665 1.66666 5.40817 1.66666 9.99984C1.66666 14.5915 5.40832 18.3332 9.99999 18.3332C14.5917 18.3332 18.3333 14.5915 18.3333 9.99984C18.3333 5.40817 14.5917 1.6665 9.99999 1.6665ZM13.625 12.9748C13.5083 13.1748 13.3 13.2832 13.0833 13.2832C12.975 13.2832 12.8667 13.2582 12.7667 13.1915L10.1833 11.6498C9.54166 11.2665 9.06666 10.4248 9.06666 9.68317V6.2665C9.06666 5.92484 9.34999 5.6415 9.69166 5.6415C10.0333 5.6415 10.3167 5.92484 10.3167 6.2665V9.68317C10.3167 9.98317 10.5667 10.4248 10.825 10.5748L13.4083 12.1165C13.7083 12.2915 13.8083 12.6748 13.625 12.9748Z" fill="#3397EF" />
                            </svg>
                            {feedbackAnswer ? moment.utc(feedbackAnswer?.startTime).format("hh:mm a") : "---"}
                        </div>
                    </div>
                </div>

                <div className="FeedbackWrap">

                    <div className="callTypes">
                        <Button className={`callButton ${toggle === false ? "active" : ""}`} name={'Mentor’s Feedback'} onClick={() => { setToggle(false) }} />
                        <Button className={`callButton ${toggle === true ? "active" : ""}`} name={'Your Feedback'} onClick={() => { setToggle(true) }} />
                    </div>
                    <div className="scheduleData mt-30">
                        <div className={`tabCall ${!toggle && "active"}`}>
                            {mentorFeedback?.length > 0 ? mentorFeedback?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="feedbackBoxAll">
                                        <div className="feedbackQuestion">How was your experience with the mentee?</div>
                                        <div className="ratingScale mt-15">
                                            <span className="useless">0</span>
                                            {options?.map((option, i) => (
                                                <span key={i} className={+item?.answer1 >= option && "active"}>{option}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">What was the mentee’s problem about?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer2}</div>
                                    </div>
                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Were you able to come up with an action plan for the mentee?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer3}</div>
                                    </div>
                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">How would you rate the mentee’s employability, if you were to hire them for your own team?</div>
                                        <div className="ratingScale mt-15">
                                            <span className="useless">0</span>
                                            {options.map((option, i) => (
                                                <span key={i} className={+item?.answer4 >= option && "active"} >{option}</span>
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )) : "No Feedback given"}

                        </div>
                        <div className={`tabCall ${toggle && "active"}`}>
                            {menteeFeedback?.length > 0 ? menteeFeedback?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="feedbackBoxAll">
                                        <div className="feedbackQuestion">How was your experience with the mentor?</div>
                                        <div className="ratingScale mt-15">
                                            <span className="useless">0</span>
                                            {options.map((option, i) => (
                                                <span key={i} className={+item?.answer1 >= option && "active"} >{option}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Did the call help you get any clarity?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer2}</div>
                                    </div>
                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Would you like to schedule a call again with the same mentor?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer3}</div>
                                    </div>
                                    <div className="feedbackBoxAll mt-20">
                                        <div className="feedbackQuestion">Was the mentor able to answer your questions?</div>
                                        <div className="feedbackAnswer mt-5">{item?.answer4}</div>
                                    </div>
                                </React.Fragment>
                            )) : "No Feedback given"}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewFeedback