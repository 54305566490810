import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/enviroment";

let token;

let setTokenValues = () => {
  token = localStorage.getItem("token");
};

export const loginUsers = createAsyncThunk(
  "login/loginUsers",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };
      const response = await fetch(`${environment.baseURL}/api/getPrepped/mentee/loginMentee`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("id", data.Data.id);
        localStorage.setItem("firstName", data.Data.firstName);
        localStorage.setItem("profileImg", data.Data.profileImg);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const validateMentee = createAsyncThunk("mentee/validateMentee", async (token,thunkAPI) => {
  try {
      const response = await fetch(environment.baseURL +
          "/api/getPrepped/mentee/validateMentee",
          {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: token,
              },
          }
      );
      if (response.statusText === 'Unauthorized') {
        return thunkAPI.rejectWithValue("Unauthorized");
      }
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("token", data.Token);
        localStorage.setItem("id", data.Data.id);
        localStorage.setItem("firstName", data.Data.firstName);
        localStorage.setItem("profileImg", data.Data.profileImg);
          return data;
      } else {
          return thunkAPI.rejectWithValue(data);
      }
  } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
  }
}
);

export const forgotPassword = createAsyncThunk(
  "login/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await fetch(`${environment.baseURL}/api/getPrepped/mentee/forgotPassword`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changeForgotPassword = createAsyncThunk(
  "login/changeForgotPassword",
  async ({ token, password }, thunkAPI) => {
    try {
      const response = await fetch(`${environment.baseURL}/api/getPrepped/mentee/changeForgotPassword`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify({ password }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const userSlice = createSlice({
  name: "loginUser",
  initialState: {
    LoginData: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginUsers.fulfilled]: (state, { payload }) => {
      console.log("fulfilled");
      state.LoginData = payload?.Data;
      state.isFetching = false;
      state.isSuccess = true;
      state.token = payload.Token;
      return state;
    },
    [loginUsers.rejected]: (state, { payload }) => {
      console.log("rejected");

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUsers.pending]: (state) => {
      state.isFetching = true;
    },
    [validateMentee.fulfilled]: (state, { payload }) => {
      state.LoginData = payload?.Data;
      state.isFetching = false;
      state.isSuccess = true;
      state.token = payload.Token;
      return state;
    },
    [validateMentee.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ?? "Something Went Wrong";
    },
    [validateMentee.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [changeForgotPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [changeForgotPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [changeForgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
  },
});


export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
