import React from "react";
import Button from "../Button/Button";
import CustomUploadBox from "../customUploadBox/CustomUploadBox";
import "./UploadImagePopupStyle.css";

// import CustomUploadBox from "./"
const UploadImagePopup = ({ popout, handleClose, value, handleUploadProfile, handleRemove, active, handleDelete, ClearhandleRemove, accept }) => {

  return (
    <div className={`popupOuter ${popout ? "active" : ""}`}>
      <div className="uploadImageContainer">

        <div className="popupHeader">
          <p className="popupTitle">Upload Image</p>
          <img className="closeWhite" src="../assets/icons/Close.svg" alt="" onClick={handleClose} />
        </div>

        <div className="popupBody">
          <CustomUploadBox
            label={"Upload Image"}
            uid={"upload-box2"}
            isImportant={true}
            onChange={handleUploadProfile}
            value={value}
            handleRemove={handleRemove}
            active={active}
            handleDelete={handleDelete}
            accept={accept}
          />
        </div>

        <div className="popupBtnGrp">
          <Button onClick={handleClose} className={'blueButton'} name={'OK'} />
          <Button onClick={ClearhandleRemove} className={'outlineBtn'} name={'Remove profile'} />
        </div>

      </div>
    </div>
  );
};

export default UploadImagePopup;
