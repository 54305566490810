import React from 'react'
import './InputFieldStyle.css';

const InputField = ({type,className,placeholder,id,labelName,checkBoxWrap, onChange, value, isError, erroMessage,name, onKeyPress}) => {
  return (
    <div className={`formGroups ${checkBoxWrap??""}`}>
        <label htmlFor={id}>{labelName} <span className='impRed'>*</span></label>
        <input type={type} className={className} placeholder={placeholder} id={id} value={value} onChange={onChange} name={name} onKeyPress={onKeyPress} />
        {isError && <span className='FormError'>{erroMessage}</span>}
    </div>
  )
}

export default InputField