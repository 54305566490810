import {configureStore} from "@reduxjs/toolkit";
import { callSlice } from "../callSlice";
import { feedbackSlice } from "../feedbackSlice";
import { profileSlice } from "../profileSlice.js";
import { userSlice } from "../userSlice";

export default configureStore({
    reducer:{
        user: userSlice.reducer,
        call:callSlice.reducer,
        profile:profileSlice.reducer,
        feedback:feedbackSlice.reducer,
    }
})