import moment from 'moment';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../componets/Button/Button';
import PageLoader from '../../componets/PageLoader/PageLoader';
import { callSliceFunction, getPastCall } from '../../reducers/callSlice';
import './FeedbackStyle.css';

const Feedback = () => {

    const { pastCallData, isFetching } = useSelector(callSliceFunction);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPastCall())
    }, [])

    return (
        <>
            {isFetching && <PageLoader />}
            <div className='feedbackWrapper'>

                <div className="profileHeader">
                    <h2 className="heading-3">Feedback Received</h2>
                </div>

                <div className="scheduleData feedbackData">

                    <div className="tabCall active">
                        {pastCallData?.result?.length > 0 ? pastCallData?.result?.map((item, index) => (
                            <div className="callersBox" key={index}>
                                <div className="callersBoxLeft">
                                    <img src={item?.GetPreppedAdmin?.profileImg ? item?.GetPreppedAdmin?.profileImg :  "/assets/img/profiledefault.png"} alt="" />
                                    <div className="callDetail">
                                        <div className="callerName">{item?.GetPreppedAdmin?.firstName ? `${item?.GetPreppedAdmin?.firstName} ${item?.GetPreppedAdmin?.lastName}` : "---"}</div>
                                        <div className="callerEmail mt-5">{item?.GetPreppedAdmin?.email ? item?.GetPreppedAdmin?.email : "---"}</div>
                                        <div className="callDetails mt-10">
                                            <div className="callBox greenCall">{item?.startTime ? moment(item?.startTime).format("ddd DD MMM YYYY") : "---"}</div>
                                            <div className="callBox blueCall">{item?.time ? item?.time : "---"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="callerBoxRight">
                                    <Button className={'outlineBtn'} name={'View Feedback'} onClick={() => { navigate(`/viewFeedback/${item?.id}`) }} />
                                </div>
                            </div>
                        )) : "No feedback received yet"}
                    </div>

                </div>

            </div>
        </>
    )
}

export default Feedback