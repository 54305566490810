import React, { useEffect, useState } from "react";
import Button from "../../componets/Button/Button";
import "./CreateFeedbackStyle.css";
import FeedbackQuestion from "./FeedbackQuestion";
import FeedbackRating from "./FeedbackRating";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  feedbackSliceFunction,
  saveMenteeFeedbackAnswers,
  clearState,
} from "../../reducers/feedbackSlice";
import "rc-slider/assets/index.css";
import PageLoader from "../../componets/PageLoader/PageLoader";
import ErrorToast from "../../componets/Toast/ErrorToast";


const CreateFeedback = () => {

  const [searchParams] = useSearchParams();
  const bookingId = searchParams.get("id") ?? "";

  const [feedbackQues, setFeedbackQues] = useState({
    answer1: 0,
    answer2: "",
    answer3: "",
    answer4: "",
  });

  const [index, setIndex] = useState(0);
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(feedbackSliceFunction);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const saveMenteeFeedback = () => {
    const obj = {
      feedbackQues,
      bookingId
    }
    // console.log("xoxox",obj);
    dispatch(saveMenteeFeedbackAnswers(obj));
  };

  const handleSubmit = () => {
    if (
      (index === 0 && feedbackQues.answer1 !== null) ||
      (index === 1 && feedbackQues.answer2 !== "") ||
      (index === 2 && feedbackQues.answer3 !== "")
    ) {
      setIndex((prev) => (prev !== 3 ? prev + 1 : 3));
    } else if (index === 3 && feedbackQues.answer4 !== "") {
      saveMenteeFeedback();
    } else {
      setIsShowError(true);
      setErrMessage("Feedback is mandatory");
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackQues((val) => ({ ...val, [name]: value }));
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/feedback", { replace: true });
      dispatch(clearState());
    }
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError])

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="createFeedbackWrapper">
        <div className="feedbackWrapBox">
          <div className="feedbackLeft">
            {index == 0 && (
              <FeedbackRating
                onChange={(e) =>
                  setFeedbackQues((prev) => ({
                    ...prev,
                    ["answer1"]:
                      e === 20
                        ? 1
                        : e === 40
                          ? 2
                          : e === 60
                            ? 3
                            : e === 80
                              ? 4
                              : e === 100
                                ? 5
                                : 0,
                  }))
                }
                name={"answer1"}
                question={
                  <div className="giveFeedbackQuestion">
                    How was your <span>experience</span> with the{" "}
                    <span>mentor</span>?
                  </div>
                }
                value={feedbackQues["answer1"]}
              />
            )}
            {index === 1 && (
              <FeedbackQuestion
                ques={
                  <div className="giveFeedbackQuestion">
                    Did the call <span>help you</span> get any{" "}
                    <span>clarity</span>?
                  </div>
                }
                options={["Yes", "No", "Few", "Most of them"]}
                onChange={handleChange}
                name={"answer2"}
              />
            )}
            {index == 2 && (
              <FeedbackQuestion
                ques={
                  <div className="giveFeedbackQuestion">
                    Would you like to <span>schedule</span> a{" "}
                    <span>call again</span> with the <span>same</span> mentor?
                  </div>
                }
                options={["Yes", "No", "Unsure"]}
                onChange={handleChange}
                name={"answer3"}
              />
            )}
            {index == 3 && (
              <FeedbackQuestion
                ques={
                  <div className="giveFeedbackQuestion">
                    Was the <span>mentor</span> able to <span>answer</span> your
                    question?
                  </div>
                }
                options={["Yes", "No", "Few", "Most of them"]}
                onChange={handleChange}
                name={"answer4"}
              />
            )}

            <div className="fedbackButtonWrap">
              {
                <Button
                  style={index === 0 ? {visibility:"hidden"} : null}
                  className={"feedbackBtnWhite imgButton"}
                  name={"Back"}
                  src={"/assets/icons/feedbackPrev.png"}
                  onClick={() => {
                    setIndex((prev) => (prev !== 0 ? prev - 1 : 0));
                  }}
                />}
              <div className="navigationWrap">
                <span
                  className={`navigationDot ${index === 0 && "active"}`}
                ></span>
                <span
                  className={`navigationDot ${index === 1 && "active"}`}
                ></span>
                <span
                  className={`navigationDot ${index === 2 && "active"}`}
                ></span>
                <span
                  className={`navigationDot ${index === 3 && "active"}`}
                ></span>
                {/* <span className="navigationDot"></span> */}
              </div>
              <Button
                className={"feedbackBtnBlack"}
                name={`${index === 3 ? "Submit" : "Next"}`}
                onClick={(prev) => handleSubmit(prev)}
              />
              {/* {index === 3 ? (
              <Button
                className={"feedbackBtnBlack"}
                name={"Submit"}
                onClick={handleSubmit}
              />
            ) : (
              <Button
                className={"feedbackBtnBlack"}
                name={"Next"}
                onClick={() =>
                  index <= 2 ? setIndex((prev) => prev + 1) : null
                }
              />
            )} */}
            </div>
          </div>
          <div className="feedbackRight">
            <img src="/assets/img/feedbackBG.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFeedback;
