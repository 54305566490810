import React from 'react'
import { useNavigate } from 'react-router-dom';
import './HeaderStyle.css'
const Header = ({ isMenu, setIsMenu }) => {

  const navigate = useNavigate();
  const pathname = window.location.pathname;

  return (
    <header className={`${pathname !== "/" && pathname !== ("/createFeedback") && localStorage.getItem("token") ? "headerMenu" : ""}`}>
      <div className="headerContainer">
        <img src="../assets/icons/logo.svg" alt="" className='logo' onClick={() => navigate("/")} />
        <div className={`hamburger-menu ${!isMenu ? '' : 'active'}`} onClick={() => setIsMenu(!isMenu)}>
          <span className="ham-lines"></span>
          <span className="ham-lines"></span>
          <span className="ham-lines"></span>
        </div>
        {/* <div className="headerRight" onClick={logout}>
              <img src="/assets/img/demoUser.png" alt="" className='userProfile'/>
        <img src="../assets/icons/logo.svg" alt="" className='logo' />
        
        
            {/* <div className="headerRight" onClick={logout}>
              <img src={ profileImg === "null" ? "/assets/img/profiledefault.png" : profileImg } alt="" className='userProfile'/>
              <img src="/assets/icons/chevron-right.png" alt="" className='dropDown'/>
            </div> */}
      </div>
    </header>
  )
}

export default Header