import React from 'react'
import Slider from 'rc-slider';


const FeedbackRating = ({question, onChange, value}) => {

  const options = [0,1,2,3,4,5];

  return (
    <div className='sliderRating'>
        {question}
        <div className='customSlider mt-40'>
          <Slider step={'20'} 
            onChange={onChange}
          />
          <div className='rangeCount mt-15'>
            {options.map((item,index) => (
              <span className={value === item && "active"} >{item}</span>
            ))}
          </div>
        </div>
    </div>
  )
}

export default FeedbackRating