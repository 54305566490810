import React, { useState, useEffect } from "react";
import Button from "../../componets/Button/Button";
import InputField from "../../componets/InputField/InputField";
import "./EditProfileStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  profileSliceFunction,
  saveMenteeDetails,
  getMenteeDetails,
  clearState,
} from "../../reducers/profileSlice";
import * as filestack from "filestack-js";
import SuccessToast from "../../componets/Toast/SuccessToast";
import ErrorToast from "../../componets/Toast/ErrorToast";
import PageLoader from "../../componets/PageLoader/PageLoader";
import moment from "moment/moment";
import UploadImagePopup from "../../../src/componets/uploadImgPopup/UploadImagePopup";

const EditProfile = () => {
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  let profileDetail = {
    linkedin: "",
    mobileNumber: "",
    companyName: "",
    designation: "",
    experience: "",
    interest: "",
    menteeType: "",
    slotDate: "",
    slotPreference: "",
  };
  const areasOfInterest = [
    "Taxation",
    "CA/CMA/CFA",
    "CAT/MBA",
    "Financial Advisory",
    "Investment Banking",
    "Auditing",
    "FP&A",
    "Financial Reporting",
    "Mergers & Acquisitions",
    "Accounting",
    "Financial Analysis",
    "Risk Management",
    "Asset Management",
    "Valuations"
  ];

  const [selectedProfileKey, setSelectedProfileKey] = useState("");
  const [urlProfile, setUrlProfile] = useState(null);
  const [url, setUrl] = useState("");
  const [uploadPopUp, setUploadPopUp] = useState(false);
  const [load, setLoad] = useState(false);
  const [active, setActive] = useState(false);
  const client = filestack.init("AcpQ2RjKSnWT5GjS9f6wOz");
  const token = {};

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { menteeData, isFetching, isSuccess } =
    useSelector(profileSliceFunction);

  const [profileData, setProfileData] = useState({ ...profileDetail });

  const profileDataOnChange = (e) => {
    const { name, value } = e.target;
    if ((name === "mobileNumber" && value.length > 10)) return;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const obj = {
      linkedin: profileData?.linkedin,
      mobileNumber: profileData?.mobileNumber,
      companyName: profileData?.companyName,
      designation: profileData?.designation,
      experience: profileData?.experience,
      interest: profileData?.interest,
      menteeType: profileData?.menteeType,
      slotDate: profileData?.slotDate,
      slotPreference: profileData?.slotPreference,
      profileImg: urlProfile,
    };
    let status = true;
    for (const key in obj) {
      if (key === "linkedin" || key === "profileImg") continue;
      if (!obj[key]) {
        status = false;
        setIsShowError(true);
        setErrMessage("Please fill all fields!");
        return;
      }
    }
    if (status) dispatch(saveMenteeDetails(obj));
    else {
      setIsShowError(true);
      setErrMessage("Please fill all fields!");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      setProfileData({ ...profileDetail });
      localStorage.setItem("profileImg", urlProfile);
      Navigate("/profile", { replace: true });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    dispatch(getMenteeDetails());
  }, []);

  useEffect(() => {
    if (menteeData) {
      setProfileData((prevState) => ({
        ...prevState,
        linkedin: menteeData?.linkedin,
        mobileNumber: menteeData?.mobileNumber,
        companyName: menteeData?.GetPrepProfession?.company,
        designation: menteeData?.GetPrepProfession?.designation,
        experience: menteeData?.GetPrepProfession?.yrsOfExp,
        interest: menteeData?.GetPrepInterests?.length > 0 && menteeData?.GetPrepInterests[0]?.interest,
        menteeType: menteeData?.menteeType,
        slotDate: moment(menteeData?.slotDate).format("YYYY-MM-DD"),
        slotPreference: menteeData?.slotPreference,
      }));
      setUrlProfile(menteeData?.profileImg);
    }
  }, [menteeData]);

  const handleUploadProfile = (event) => {

    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      setLoad(true);
      client
        .upload(files, {}, token)
        .then((res) => {
          if (res) {
            setSelectedProfileKey(res.name);
            setUrl(res.url);
            setLoad(false);
            setActive(true);
            // setUploadPopUp(false)
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          setLoad(false);
          setIsShowError(true);
          setErrMessage("Something went wrong!");
          // setErrMessage("All fields are mandatory")
        });
    }
  };

  const clearProfile = () => {
    setUrl(null);
    setSelectedProfileKey(null);
  };

  const ClearhandleRemove = () => {
    setUrlProfile(null);
    setUploadPopUp(false);
  };

  useEffect(() => {
    if (profileData?.menteeType !== menteeData?.menteeType) setProfileData(prev => ({ ...prev, companyName: "", designation: "", experience: "" }))
    else {
      setProfileData(prev => ({
        ...prev, companyName: menteeData?.GetPrepProfession?.company,
        designation: menteeData?.GetPrepProfession?.designation,
        experience: menteeData?.GetPrepProfession?.yrsOfExp,
      }))
    }
  }, [profileData?.menteeType])

  return (
    <>
      {(isFetching || load) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="editWrapper">
        {/* <SuccessToast /> */}
        {/* <ErrorToast /> */}

        <div className="profileHeader">
          <UploadImagePopup
            active={active}
            popout={uploadPopUp}
            handleClose={() => {
              setSelectedProfileKey(null);
              setUploadPopUp(false);
              if (active) setUrlProfile(url)
            }}
            handleUploadProfile={handleUploadProfile}
            value={selectedProfileKey}
            handleRemove={clearProfile}
            handleDelete={() => {
              if (selectedProfileKey !== "") setActive(false)
              else setActive(true)
            }}
            ClearhandleRemove={ClearhandleRemove}
            // accept={".jpg"}
          />
          <h2 className="heading-3">My Profile</h2>
          <Button
            className={"blueButton"}
            name={"Save Changes"}
            onClick={handleSubmit}
          />
        </div>

        <div className="editProfileWrapper">
          <div className="mentorProfile profileMentor">
            <div className="editProfileWrap">
              {
                <img
                  src={
                    urlProfile
                      ? urlProfile
                      : "https://events.powerathletehq.com/wp-content/uploads/2018/06/grey-person-coming-soon-e1529779699986.jpg"
                  }
                  alt=""
                  className="mentorImg"
                />
              }
              <img
                src="/assets/icons/editProfile.svg"
                className="editProfile"
                onClick={() => setUploadPopUp(true)}
              ></img>
            </div>
            <div className="mentorDetails">
              <p className="mentorName">
                {menteeData?.firstName ? `${menteeData?.firstName} ${menteeData?.lastName}` : "---"}
              </p>
              <p className="designation">{menteeData?.menteeType ? menteeData?.menteeType : "---"}</p>
            </div>
          </div>

          <div className="profileDetails mt-40">
            <InputField
              type={"text"}
              name="linkedin"
              value={profileData?.linkedin}
              className={"FormInput mb-30"}
              placeholder={"linkedin.com/user"}
              id={"linkedinProfile"}
              labelName={"LinkedIn Profile"}
              onChange={profileDataOnChange}
            />

            <InputField
              type={"number"}
              name="mobileNumber"
              value={profileData?.mobileNumber}
              className={"FormInput"}
              placeholder={"9876543210"}
              id={"name"}
              labelName={"Contact Number"}
              onChange={profileDataOnChange}
            />
            <small className="smallNotification mt-10 mb-30">
              Contact number won’t be visible on your public profile
            </small>
            {/* 
            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group22" className="formLabel">
                Slot Preference
              </label>
              <div className="feedbackRadioGroup fullwidth">
                <input
                  type="date"
                  name="slotDate"
                  value={profileData?.slotDate}
                  className="timeWrapper"
                  onChange={profileDataOnChange}
                  min={moment(new Date()).format("YYYY-MM-DD")}
                />
                {["Morning", "Afternoon", "Evening"]?.map((res, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      value={res}
                      type="radio"
                      id={i}
                      name="slotPreference"
                      checked={profileData?.slotPreference === res}
                      onChange={profileDataOnChange}
                    />
                    <label for={i}>{res}</label>
                  </div>
                ))}
              </div>
            </div> */}

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group" className="formLabel">
                Key Areas of interest <span class="impRed">*</span>
              </label>
              <div className="feedbackRadioGroup">
                {areasOfInterest?.map((res, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      value={res}
                      type="radio"
                      id={"i" + i}
                      name="interest"
                      checked={profileData?.interest === res}
                      onChange={profileDataOnChange}
                    />
                    <label for={"i" + i}>{res}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="formGroup profileRadioGroup">
              <label htmlFor="radio-group3" className="formLabel">
                What's Describe you Best <span class="impRed">*</span>
              </label>
              <div className="feedbackRadioGroup fullwidth">
                {["Student", "Working"]?.map((res, i) => (
                  <div className="radioBtnOuter" key={i}>
                    <input
                      value={res}
                      type="radio"
                      id={"m" + i}
                      name="menteeType"
                      checked={profileData?.menteeType === res}
                      onChange={profileDataOnChange}
                    />
                    <label for={"m" + i}>{res}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="editProfileDetailNew">
              <InputField
                type={"text"}
                className={"FormInput mb-30"}
                id={"companyName"}
                value={profileData?.companyName}
                name={"companyName"}
                labelName={
                  profileData?.menteeType === "Student"
                    ? "College"
                    : "Company"
                }
                placeholder={
                  profileData?.menteeType === "Student"
                    ? "Enter College name"
                    : "Enter company name"
                }
                onChange={profileDataOnChange}
              />

              <InputField
                type={"text"}
                className={"FormInput mb-30"}
                id={"Designation"}
                value={profileData?.designation}
                name="designation"
                placeholder={
                  profileData?.menteeType === "Student"
                    ? "Degree"
                    : "Designation"
                }
                labelName={
                  profileData?.menteeType === "Student"
                    ? "Degree"
                    : "Designation"
                }
                onChange={profileDataOnChange}
              />

              <InputField
                type={"number"}
                className={"FormInput"}
                id={"experience"}
                value={profileData?.experience}
                name="experience"
                labelName={
                  profileData?.menteeType === "Student"
                    ? "Current year"
                    : "Experience"
                }
                placeholder={
                  profileData?.menteeType === "Student"
                    ? "Current year"
                    : "Experience"
                }
                onChange={profileDataOnChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
