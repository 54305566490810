import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../componets/Button/Button';
import PageLoader from '../../componets/PageLoader/PageLoader';
import { getUpcomingCall, getPastCall, callSliceFunction, getMentorAvailableSlots, clearState, rescheduleMeeting } from "../../reducers/callSlice";
import './CallsBookingStyle.css';
import MeetingSchedulerPopoup from "../../componets/MeetingSchedulerPopoup/MeetingSchedulerPopoup";
import ErrorToast from '../../componets/Toast/ErrorToast';

const CallsBooking = () => {
    const [pageToggler, setPageToggler] = useState(false);
    const [rescheduleToggler, setRescheduleToggler] = useState(false);
    const [rescheduleDate, setRescheduleDate] = useState("");
    const [mentorId, setMentorId] = useState("");
    const [rescheduleSlotId, setRescheduleSlotId] = useState("");
    const [rescheduleSuccess, setRescheduleSuccess] = useState(false);

    //Toast states
    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const testTime = (time) => {
        const today = moment();
        const callTime = moment(time);
        const diff = callTime.diff(today, "minutes");
        if ((diff - 330) <= 10) return false;
        else return true;
    };

    useEffect(() => {
        dispatch(getPastCall());
    }, [])

    useEffect(() => {
        if (pageToggler) {
            dispatch(getPastCall())
        }
        else dispatch(getUpcomingCall());

    }, [pageToggler])
    const { pastCallData, upcomingCallData, isFetching, isSuccess, isError, errorMessage, mentorAvailableSlots, rescheduledData } = useSelector(callSliceFunction);

    //Checking for empty feedback
    const completedCalls = pastCallData?.result?.filter(item => item.isCompleted);
    completedCalls?.forEach((item) => {
        const menteePendingFeedback = item?.getPrepFeedbackAnswers?.filter((feedback) => feedback?.menteeId);
        if (menteePendingFeedback?.length === 0) navigate(`/createFeedback?id=${item.id}`);
    });

    const handleClickViewSlots = (id) => {
        setRescheduleDate(new Date());
        setRescheduleToggler(true);
        setMentorId(id);
    };

    const handleConfirmReschedule = () => {
        dispatch(rescheduleMeeting(rescheduleSlotId));
    }

    useEffect(() => {
        if (mentorId) {
            const obj = {
                date: moment(rescheduleDate).format("YYYY-MM-DD"),
                mentorId: mentorId,
            };
            dispatch(getMentorAvailableSlots(obj));
        }
    }, [rescheduleDate]);

    useEffect(() => {
        if (isError) {
            setIsShowError(true);
            setErrMessage(errorMessage);
            dispatch(clearState());
        }
    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            setRescheduleSuccess(true);
            setPageToggler(false);
            dispatch(getUpcomingCall());
            dispatch(clearState());
        }
    }, [isSuccess]);

    return (
        <>
            {isFetching && <PageLoader />}
            <MeetingSchedulerPopoup
                isActive={rescheduleToggler}
                setIsActive={setRescheduleToggler}
                date={rescheduleDate}
                setDate={setRescheduleDate}
                slots={mentorAvailableSlots?.length > 0 && [...mentorAvailableSlots].filter(slot => !slot.isBooked)}
                slotId={rescheduleSlotId}
                setSlotId={setRescheduleSlotId}
                onClick={handleConfirmReschedule}
                bookedSuccess={rescheduleSuccess}
                bookedData={rescheduledData}
            />
            <ErrorToast
                show={isShowError}
                setShow={() => setIsShowError(false)}
                message={errMessage}
            />
            <div className="bookingsWrapper">

                <div className="profileHeader">
                    <h2 className="heading-3">All Calls</h2>
                </div>

                <div className="CallsWrap">

                    <div className="callTypes">
                        <Button className={`callButton ${pageToggler === false ? "active" : ""}`} name={'Upcoming Calls'} onClick={() => { setPageToggler(false) }} />
                        <Button className={`callButton ${pageToggler === true ? "active" : ""}`} name={'Past Calls'} onClick={() => { setPageToggler(true) }} />
                    </div>

                    <div className="scheduleData">

                        <div className={`tabCall ${!pageToggler && "active"}`}>
                            {upcomingCallData?.length > 0 ? upcomingCallData?.map((value, index) => (
                                <div className="callersBox" key={index}>
                                    <div className="callersBoxLeft">
                                        <img className='callImg' src={value?.GetPreppedAdmin?.profileImg ? value?.GetPreppedAdmin?.profileImg : "/assets/img/profiledefault.png"} alt="" />
                                        <div className="callDetail">
                                            <div className="callerName">{value?.GetPreppedAdmin?.firstName} {value?.GetPreppedAdmin?.lastName}</div>
                                            <div className="callerEmail mt-5">{value?.GetPreppedAdmin?.email}</div>
                                            <div className="callDetails mt-10">
                                                <div className="callBox greenCall">{moment(value?.startTime).format("ddd DD MMM YYYY")}</div>
                                                <div className="callBox blueCall">{value?.time}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="callerBoxRight">
                                        <Button className={`CallButton ${!testTime(value?.startTime) && "active"}`} disabled={testTime(value?.startTime)} name={'Start Call'} onClick={() => { window.open(value?.meetingLink) }} />
                                    </div>
                                </div>)) : "No calls yet"}
                        </div>
                    </div>

                    <div className="scheduleData">
                        <div className={`tabCall ${pageToggler && "active"}`}>
                            {pastCallData?.result?.length > 0 ?
                                pastCallData?.result?.map((item, index) => (
                                    <div className="callersBox" key={index}>
                                        <div className="callersBoxLeft">
                                            <img src={item?.GetPreppedAdmin?.profileImg ? item?.GetPreppedAdmin?.profileImg : "/assets/img/profiledefault.png"} alt="" />
                                            <div className="callDetail">
                                                <div className="callerName">{item?.GetPreppedAdmin?.firstName + " " + item?.GetPreppedAdmin?.lastName}</div>
                                                <div className="callerEmail mt-5">{item?.GetPreppedAdmin?.email}</div>
                                                <div className="callDetails mt-10">
                                                    <div className="callBox greenCall">{moment(item?.startTime).format("ddd DD MMM YYYY")}</div>
                                                    <div className="callBox blueCall">{item?.time}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {(!index && pastCallData?.isReschedule) && <div className="callerBoxRight">
                                            <Button className={`CallButton active`} name={'Reschedule'} onClick={() => handleClickViewSlots(item?.GetPreppedAdmin?.id)} />
                                        </div>}
                                    </div>
                                ))

                                :
                                "No calls yet"}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CallsBooking