import React from "react";
import "./MeetingSchedulerPopoupStyle.css";
import { Calendar } from "react-calendar";
import SlotSelector from "../../componets/SlotSelector/SlotSelector";
import moment from "moment/moment";

const MeetingSchedulerPopoup = ({
  isActive,
  setIsActive,
  date,
  setDate,
  slots,
  slotId,
  setSlotId,
  onClick,
  bookedSuccess,
  bookedData
}) => {
  return (
    <>
      <div className={`popupOuter reschedule ${isActive ? "active" : ""}`}>
        <div className={`popupContainer ${!bookedSuccess && "active"}`}>
          <div className="popupHeader">
            <p className="popupTitle">Select a Date & Time</p>
            <img
              src="/assets/icons/Close.svg"
              className="closeWhite"
              alt=""
              onClick={() => {
                setIsActive(false);
              }}
            />
          </div>
          <div className="popupBody">
            <div className="schedulerContainer">
              <div className="calendarOuter">
                <Calendar
                  minDate={new Date()}
                  maxDate={new Date(moment().add(31,"days"))}
                  value={date}
                  onChange={(e) => setDate(e)}
                />
              </div>
              <div className="selectInterviewContainer">
                <p className="month">Available Slot</p>
                <div className="slotsOuter">
                  {slots?.length > 0
                    ? slots?.map((item, index) => (
                        <SlotSelector
                          text={item.time}
                          id={`slot${index}`}
                          name={"slotter"}
                          checked={slotId === item.id}
                          onChange={() =>
                            slotId === item.id
                              ? setSlotId("")
                              : setSlotId(item.id)
                          }
                          value={"value"}
                          onClick={onClick}
                        />
                      ))
                    : "No slots available. Try another date"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`popupContainer wrapReschedule ${bookedSuccess && "active"}`}>
          {console.log(bookedData)}
          <div className="popupHeader">
            <img
              src="/assets/icons/Close.svg"
              alt=""
              onClick={() => {
                setIsActive(false);
              }}
            />
          </div>
          <div className="popupBody">
            <div class="successContainer">
              <div class="successContainerBody ">
                <div class="successContainerContent">
                  <h4 class="sectionHeading">Your Call is Confirmed!</h4>
                  <p class="sectionSubHeading">It is scheduled for</p>
                  <div class="tags">
                    <div class="primaryTag">{bookedData?.time}</div>
                    <div class="primaryTag">{moment(bookedData?.startTime).format("ddd DD MMM YYYY")}</div>
                  </div>
                  <div class="marsterDetailsBox">
                    <div class="masterImgBox">
                      <img
                        src="https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
                        alt=""
                        class="masterImg"
                      />
                      <div class="masterDetail">
                        <p class="masterName">{bookedData?.GetPreppedAdmin?.firstName ? `${bookedData?.GetPreppedAdmin?.firstName} ${bookedData?.GetPreppedAdmin?.lastName}` : "---"}</p>
                        <p class="masterPosition">
                          {bookedData?.GetPreppedAdmin?.GetPrepProfession?.designation ? bookedData?.GetPreppedAdmin?.GetPrepProfession?.designation : "---"}{bookedData?.GetPreppedAdmin?.GetPrepProfession?.company ? `, ${bookedData?.GetPreppedAdmin?.GetPrepProfession?.company}` : "---"}
                        </p>
                      </div>
                    </div>
                    <p class="masterOtherDetails">
                      Areas of expertise: {bookedData?.GetPreppedAdmin?.GetPrepInterests?.length > 0 ? bookedData?.GetPreppedAdmin?.GetPrepInterests?.map((item,index) => (
                        <span>{index ? "," : null}{item.interest}</span>
                      )) : "---"}
                    </p>
                    <p class="masterOtherDetails">
                      Years of Experience: <span>{bookedData?.GetPreppedAdmin?.GetPrepProfession?.yrsOfExp ? bookedData?.GetPreppedAdmin?.GetPrepProfession?.yrsOfExp : "---"}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingSchedulerPopoup;
